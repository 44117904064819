// Header.js
import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./header-elements/Navbar";
import { getPageSettingProperty } from "../../../utils/pageSettings";
import BetaBanner from "./header-elements/BetaBanner";

const Header = ({ className, progressBar }) => {
  const { pathname, search } = useLocation();
  const { seller } = Object.fromEntries(new URLSearchParams(search));

  const showStepper = getPageSettingProperty(pathname, "showStepper");

  const showBetaBanner = () => {
    const validPaths = ["/schedule", "/review", "/trip", "/checkout"];
    return validPaths.includes(pathname) && !seller;
  };

  return (
    <div className={`bg-white sticky top-0 z-20 ${className}`}>
      {showBetaBanner() && <BetaBanner />}
      <div className="w-full max-w-screen-xl mx-auto">
        <Navbar
          showStepper={showStepper}
          seller={seller}
          progressBar={progressBar}
        />
        <hr className="border-gray-200 sm:mx-auto dark:border-gray-700" />
      </div>
    </div>
  );
};

export default Header;
