import { useState, useEffect } from "react";

const useSessionData = (defaultStates, clearAll) => {
  const [states, setStates] = useState(
    defaultStates ? defaultStates : { data: {} }
  );

  useEffect(() => {
    if (clearAll) {
      sessionStorage.clear();
    }
    const storedData = sessionStorage.getItem("scheduleFormData");
    if (storedData) {
      setStates(JSON.parse(storedData));
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem("scheduleFormData", JSON.stringify(states));
  }, [states]);

  const updateFormState = (field, value) => {
    setStates((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [field]: value,
      },
    }));
  };

  return { states, updateFormState };
};

export default useSessionData;
