import React from "react";
import { useLocation } from "react-router-dom";
import Header from "./global/Header";
import Footer from "./global/Footer";
import { motion } from "framer-motion";
import { getPageSettingProperty } from "../../utils/pageSettings";

const Global = ({ children, progressBar }) => {
  const { pathname } = useLocation();
  const center = getPageSettingProperty(pathname, "center");
  const content = getPageSettingProperty(pathname, "content");

  return (
    <motion.div
      className="min-h-screen flex flex-col"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Header className="mb-4" progressBar={progressBar} />
      <main
        className={`flex justify-center flex-grow ${
          center ? "items-center" : ""
        } ${content ? "max-w-screen-xl mx-auto px-6 w-full" : "w-full px-4"}`}
      >
        <div className="w-full">{children}</div>
      </main>
      <Footer className="w-full" />
    </motion.div>
  );
};

export default Global;
