// Header.js
import React from "react";
import { useLocation } from "react-router-dom";

const FormStepper = ({ className, progressBar }) => {
  const { search } = useLocation();
  const { stage } = Object.fromEntries(new URLSearchParams(search));
  const numericStage = stage ? Number(stage) : null;

  const headerElements = {
    setOne: {
      itemOne: { text: "Estimate", step: 1, stages: [1] },
      itemTwo: { text: "Review", step: 2, stages: [2] },
      itemThree: { text: "Schedule", step: 3, stages: [3, 4] },
    },
    setTwo: {
      itemOne: { text: "Pending", step: 1, stages: [5] },
      itemTwo: { text: "Scheduled", step: 2, stages: [6] },
      itemThree: { text: "Delivered", step: 3, stages: [7] },
    },
  };

  const toRender =
    numericStage <= 4 ? headerElements.setOne : headerElements.setTwo;

  const renderListItems = (elements) => {
    return Object.entries(elements).map(([key, value]) => (
      <li
        key={key}
        className={`flex flex-col items-center justify-center ${
          value.stages.includes(numericStage)
            ? "text-blue-600 dark:text-blue-500"
            : ""
        }`}
      >
        <div className="flex items-center justify-center">
          <span
            className={`hidden md:flex items-center justify-center w-5 h-5 me-2 text-xs border ${
              value.stages.includes(numericStage)
                ? "border-blue-600"
                : "border-gray-500"
            } rounded-full shrink-0 ${
              value.stages.includes(numericStage)
                ? "dark:border-blue-500"
                : "dark:border-gray-400"
            }`}
          >
            {value.step}
          </span>
          <div>{value.text}</div>

          {key !== "itemThree" && (
            <svg
              className={`w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180 ${
                value.stages.includes(numericStage)
                  ? "text-blue-600 dark:text-blue-500"
                  : "text-gray-500 dark:text-gray-400"
              }`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 12 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m7 9 4-4-4-4M1 9l4-4-4-4"
              />
            </svg>
          )}
        </div>
        <div
          className={`w-2/3 bg-gray-200 rounded-full h-1 mt-0.5 ${className}`}
          style={{
            opacity: value.text !== "Estimate" ? 0 : stage !== "1" ? 0 : 1,
          }} // Conditional opacity based on value.text
        >
          <div
            className="bg-blue-600 h-1 rounded-full transition-all duration-2000 ease-out"
            style={{
              width: `${progressBar}%`,
            }} // Conditionally hiding the inner div
          ></div>
        </div>
      </li>
    ));
  };

  return (
    <ol className="flex justify-center space-x-2 text-sm text-center text-gray-500 sm:text-base sm:space-x-4">
      {renderListItems(toRender)}
    </ol>
  );
};

export default FormStepper;
