// src/mixpanel.js

// Import Mixpanel SDK
import mixpanel from "mixpanel-browser";

// Initialize Mixpanel
mixpanel.init("9d222bc3b4003be1920e96274de862c0", {
  debug: false,
  track_pageview: true,
  persistence: "localStorage",
});

// Explicitly export the initialized mixpanel instance as default
export default mixpanel;
