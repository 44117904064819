import React, { useState, useEffect, useRef, useContext } from "react";
import CondsensedPriBtn from "../../../buttons/CondsensedPriBtn";
import CondsensedSecBtn from "../../../buttons/CondsensedSecBtn";
import useCustomNavigation from "../../../../utils/hooks/useCustomNavigation";
import { AuthContext } from "../../../../utils/authentication/AuthContext";
import NavMenuExpanded from "./NavMenuExpanded";
import MenuItemBtn from "./MenuItemBtn";
import FormStepper from "../header-elements/FormStepper";
import mixpanel from "../../../../mixpanel";

const Navbar = ({ showStepper, seller, progressBar }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  const { loggedIn, setLoggedIn } = useContext(AuthContext);
  const customNavigation = useCustomNavigation();

  const handleMenuToggle = (event) => {
    event.stopPropagation();
    setIsMenuOpen((prev) => !prev);
    mixpanel.track("Toggled Menu", {});
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleNewPickup = () => {
    customNavigation("/estimate");
    mixpanel.track("Clicked New Pickup", {});
  };

  const handleLogin = () => {
    customNavigation("/login");
    mixpanel.track("Clicked Log In", {});
  };

  return (
    <div
      className={`bg-white mx-2 py-4 relative z-20 ${
        loggedIn ? "md:py-5" : "md:py-2.5"
      }`}
    >
      <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
        <div>
          <a
            href="/"
            className={`flex items-center`}
            onClick={() => mixpanel.track("Clicked Header Logo", {})}
          >
            <img
              src="/images/marketing/delivery-truck-side.png"
              className="mr-3 h-6"
              alt="KW Pickup Logo"
            />
            <span
              className={`${
                showStepper ? "hidden md:flex" : ""
              } self-center text-xl font-semibold whitespace-nowrap`}
            >
              KW Pickup
            </span>
          </a>
        </div>
        {showStepper && !seller && <FormStepper progressBar={progressBar} />}
        <div className="flex items-center space-x-2 md:order-2">
          {!loggedIn && (
            <div className="hidden lg:flex gap-2">
              <CondsensedSecBtn action={handleLogin}>Log In</CondsensedSecBtn>
              <CondsensedPriBtn action={handleNewPickup} enabled={true}>
                + New Pickup
              </CondsensedPriBtn>
            </div>
          )}
          <MenuItemBtn
            handleMenuToggle={handleMenuToggle}
            isMenuOpen={isMenuOpen}
            buttonRef={buttonRef}
          />
        </div>
        <NavMenuExpanded
          menuRef={menuRef}
          isMenuOpen={isMenuOpen}
          loggedIn={loggedIn}
          setLoggedIn={setLoggedIn}
          setIsMenuOpen={setIsMenuOpen}
          customNavigation={customNavigation}
        />
      </div>
    </div>
  );
};

export default Navbar;
